export default {
  title: {
    en: `Here's how it works:`,
  },
  titleAb: {
    en: '30 Days of Fragrance, Tailored to Your Lifestyle',
  },
  features: {
    bottles: {
      title: {
        en: 'Choose your<br class="onlyDesktop"/> scents',
      },
      subtitle: {
        en: 'Explore over <span class="text-gold-70">{fragrancesAmount} designer scents</span>—<br class="onlyMobile"/>new arrivals are added every month!',
      },
      subtitleAiSummary: {
        en: `
          Explore over <span class="text-gold-70">{fragrancesAmount} designer scents</span> with <br class="onlyMobile"/>
          new arrivals monthly! Our AI reviews <br class="onlyMobile"/>help you find your perfect fragrance.
        `,
      },
    },
    cart: {
      title: {
        en: 'Receive your<br/>fragrance',
      },
      subtitle: {
        en: 'Your 8 mL vial will be delivered straight<br/>to your door every month.',
      },
    },
    case: {
      title: {
        en: 'Spray, play,<br class="onlyDesktop"/> and repeat',
      },
      subtitle: {
        en: 'Add all of the scents you want to try<br class="onlyDesktop"/> in<br class="onlyMobile"/> the upcoming months to your<br class="onlyDesktop"/> fragrance queue.',
      },
    },
    handshake: {
      title: {
        en: 'No commitment',
      },
      subtitle: {
        en: 'Bottles piling up? Skip a month,<br class="onlyDesktop"/> or cancel anytime<br class="onlyDesktop"/> without consequences.',
      },
    },
  },
  imageAlt: {
    en: 'How it works',
  },
  vialImageDetails: {
    en: 'Travel-size 8mL: ~120 sprays, lasts 30 days.',
  },
}
