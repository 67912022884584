import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { constants } from 'helpers'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useIntl } from 'intl'

import { useAb, usePrefersReducedMotion } from 'hooks'
import { useVisibilityTrigger } from 'intersection-observer'
import { track } from 'analytics'
import { WidthContainer } from 'components/layout'
import { Image, Text, Video } from 'components/dataDisplay'
import DiscountBadgeBase from 'compositions/DiscountBadgeBase/DiscountBadgeBase'

import GetStartedButton from '../GetStartedButton/GetStartedButton'

import bottlesGif from './images/bottles.gif?src-loader'
import bottlesStatic from './images/bottles-static.jpg'
import cartGif from './images/cart.gif?src-loader'
import cartStatic from './images/cart-static.jpg'
import caseGif from './images/case.gif?src-loader'
import caseStatic from './images/case-static.jpg'
import handshakeGif from './images/handshake.gif?src-loader'
import handshakeStatic from './images/handshake-static.jpg'
import vialsVideo from './images/vials.mp4'

import type { HomePageCustomBlockButton } from '../../View'

import messages from './messages'
import bannerMessages from '../HeroSection/components/SubscriptionOfferBadge/messages'


type HowItWorksSectionWithAnimationsProps = {
  className?: string
  startButtonMessage?: string | Intl.Message
  showStartButton?: boolean
  customButton?: HomePageCustomBlockButton
  // TODO: remove following props if CRO-618 fails
  discount?: string
  offerType?: SubscriptionModule.OfferType
}

const HowItWorksSectionWithAnimations: React.FunctionComponent<HowItWorksSectionWithAnimationsProps> = (props) => {
  const { className, startButtonMessage, showStartButton = true, customButton, discount, offerType } = props

  const isAiSummaryEnabled = useFeatureIsOn(constants.features.aiSummary)
  const isAiSummaryLandingHowFeatureEnabled = useFeatureIsOn(constants.features.aiSummaryLandingHow)
  // do not delete this line, it's required for AA tests
  const isHowItWorksEnabled = useFeatureIsOn(constants.features.aaHowItWorks)

  const isHowItWorksPlusVialEnabled = useAb(constants.abTests.homeHowItWorksPlusVial) === 'b'

  // TODO remove if CRO-618 fails
  const howItWorksRef = useVisibilityTrigger(() => {
    track('How it works display')
  })

  const { isMobile } = useDevice()
  const prefersReducedMotion = usePrefersReducedMotion()

  const isAiSummaryLandingHowEnabled = isAiSummaryEnabled && isAiSummaryLandingHowFeatureEnabled

  const offerTextStylesMap = {
    discount: isMobile ? 'text-[24rem]/[24rem] -mt-8' : 'text-[32rem]/[32rem] -mt-8',
    freeTrial: isMobile ? 'text-[20rem]/[20rem]' : 'text-[32rem]/[32rem]',
    free2ndMonth: isMobile ? 'text-[14rem]/[14rem]' : 'text-[21rem]/[21rem]',
    freeProduct: isMobile ? 'text-[16rem]/[16rem]' : 'text-[22rem]/[22rem]',
  }

  return (
    <WidthContainer className={cx(className, 'flex flex-col items-center')} ref={howItWorksRef}>
      {
        isHowItWorksPlusVialEnabled ? (
          <>
            <Text
              className={cx('max-w-560 text-center', isMobile ? 'mb-56' : 'mb-64')}
              style={isMobile ? 'h5' : 'h4'}
              message={messages.titleAb}
              html
            />
            <div className={cx('flex', isMobile ? 'flex-col gap-40' : 'items-center gap-64')}>
              <div className={cx('grid gap-32', isMobile ? 'grid-cols-1 gap-40' : 'max-w-[600rem] grid-cols-2')}>
                <FeatureItem
                  animationImageSrc={bottlesGif}
                  staticImageSrc={bottlesStatic}
                  title={messages.features.bottles.title}
                  subtitle={{
                    ...(isAiSummaryLandingHowEnabled ? messages.features.bottles.subtitleAiSummary : messages.features.bottles.subtitle),
                    values: {
                      fragrancesAmount: constants.businessLogic.fragrancesAmount,
                    },
                  }}
                />
                <FeatureItem
                  animationImageSrc={cartGif}
                  staticImageSrc={cartStatic}
                  title={messages.features.cart.title}
                  subtitle={messages.features.cart.subtitle}
                />
                <FeatureItem
                  animationImageSrc={caseGif}
                  staticImageSrc={caseStatic}
                  title={messages.features.case.title}
                  subtitle={messages.features.case.subtitle}
                />
                <FeatureItem
                  animationImageSrc={handshakeGif}
                  staticImageSrc={handshakeStatic}
                  title={messages.features.handshake.title}
                  subtitle={messages.features.handshake.subtitle}
                />
              </div>
              <div className="relative h-fit">
                <Video
                  className={isMobile ? 'w-[376rem]' : 'w-[496rem]'}
                  url={vialsVideo}
                  type="mp4"
                  autoplay={!prefersReducedMotion}
                  muted
                  loop
                  withBackground={false}
                />
                <Text
                  className={cx('z-2 relative text-center', isMobile ? '-mt-24 ml-56' : 'ml-88 -mt-36')}
                  style={isMobile ? 'p5' : 'p2'}
                  message={messages.vialImageDetails}
                />
                <DiscountBadgeBase className={cx('z-2 font-tt-norms-pro absolute font-bold uppercase', isMobile ? '-bottom-8 size-96' : '-bottom-24 size-[136rem]')}>
                  <div className="flex flex-col justify-center gap-4">
                    <Text
                      style={null}
                      className={cx('text-gold-30', offerTextStylesMap[offerType])}
                      message={{ ...bannerMessages.offer[offerType], values: { discount } }}
                      html
                    />
                    <Text
                      style={null}
                      className={cx('text-white', isMobile ? 'text-[8rem]/[8rem]' : 'text-[12rem]/[12rem]')}
                      message={bannerMessages.freeCase}
                    />
                  </div>
                </DiscountBadgeBase>
              </div>
            </div>
          </>
        ) : (
          <>
            <Text
              className={cx('text-center', isMobile ? 'mb-56' : 'mb-64')}
              style={isMobile ? 'h5' : 'h3'}
              message={messages.title}
              html
            />
            <div className={cx('flex', isMobile ? 'flex-col gap-40' : 'items-baseline gap-16')}>
              <FeatureItem
                animationImageSrc={bottlesGif}
                staticImageSrc={bottlesStatic}
                title={messages.features.bottles.title}
                subtitle={{
                  ...(isAiSummaryLandingHowEnabled ? messages.features.bottles.subtitleAiSummary : messages.features.bottles.subtitle),
                  values: {
                    fragrancesAmount: constants.businessLogic.fragrancesAmount,
                  },
                }}
              />
              <FeatureItem
                animationImageSrc={cartGif}
                staticImageSrc={cartStatic}
                title={messages.features.cart.title}
                subtitle={messages.features.cart.subtitle}
              />
              <FeatureItem
                animationImageSrc={caseGif}
                staticImageSrc={caseStatic}
                title={messages.features.case.title}
                subtitle={messages.features.case.subtitle}
              />
              <FeatureItem
                animationImageSrc={handshakeGif}
                staticImageSrc={handshakeStatic}
                title={messages.features.handshake.title}
                subtitle={messages.features.handshake.subtitle}
              />
            </div>
          </>

        )
      }

      {
        showStartButton && (
          <GetStartedButton
            className="mt-56"
            title={startButtonMessage}
            width={320}
            fullWidthOnMobile
            placement="How it works"
            {...customButton}
          />
        )
      }
    </WidthContainer>
  )
}

type FeatureItemProps = {
  className?: string
  animationImageSrc: Image.Src
  staticImageSrc: string
  title: string | Intl.Message
  subtitle: string | Intl.Message
}

const FeatureItem: React.FunctionComponent<FeatureItemProps> = (props) => {
  const { className, animationImageSrc, staticImageSrc, title, subtitle } = props

  const intl = useIntl()

  const { isMobile } = useDevice()

  return (
    <div className={cx(className, 'flex items-center', isMobile ? 'gap-32' : 'min-w-[273rem] flex-col gap-16 text-center')}>
      <picture>
        <source
          srcSet={staticImageSrc}
          media="(prefers-reduced-motion: reduce)"
          width={80}
          height={80}
        />
        <Image
          className="size-[80rem] flex-none"
          src={animationImageSrc}
          alt={intl.formatMessage(messages.imageAlt)}
          remWidth={80}
          lazy
          unoptimized
        />
      </picture>
      <div className="flex-1">
        <Text
          style="h6"
          message={title}
          html
        />
        <Text
          className="mt-8"
          style="p4"
          message={subtitle}
          html
        />
      </div>
    </div>
  )
}


export default React.memo(HowItWorksSectionWithAnimations)
